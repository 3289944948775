define('frontend/components/credit-card', ['exports', 'ember', 'frontend/config/environment', 'stripe'], function (exports, _ember, _frontendConfigEnvironment, _stripe) {
   exports['default'] = _ember['default'].Component.extend({
      authManager: _ember['default'].inject.service('auth-manager'),
      store: _ember['default'].inject.service(),
      borderClass: null,
      classNames: ['list-group-item'],
      card: null,
      canEdit: false,
      defaultCardID: null,
      disabled: true,
      hasAgreed: false,
      isEditing: false,
      isExpanded: false,
      isInvalid: false,
      isNewCard: false,
      readOnly: false,
      isNotEditing: _ember['default'].computed.not('isEditing'),
      notReadOnly: _ember['default'].computed.not('readOnly'),
      stripe: null,
      stripeElements: null,

      idNumber: _ember['default'].computed(function () {
         return this.get('itemIndex') + 1;
      }),
      cardNum: _ember['default'].computed(function () {
         if (this.get('isNewCard')) {
            return '';
         } else {
            return 'XXXX-XXXX-XXXX-' + this.get('card.last4');
         }
      }),
      cardType: _ember['default'].computed(function () {
         var brand = this.get('card.brand');
         if (brand === 'Visa') {
            return 'card-logo-visa';
         } else if (brand === 'MasterCard') {
            return 'card-logo-mastercard';
         } else if (brand === 'American Express') {
            return 'card-logo-amex';
         } else {
            return 'card-logo-unknown';
         }
      }),
      cardExpiry: _ember['default'].computed(function () {
         if (this.get('isNewCard')) {
            return '';
         } else {
            var month = this.get('card.exp_month');
            var mnth = month.toString();
            if (mnth.length < 2) {
               mnth = "0" + mnth;
            }
            return mnth + "/" + this.get('card.exp_year');
         }
      }),
      isDefaultCard: _ember['default'].computed(function () {
         var card = this.get('card');
         if (card) {
            if (this.get('defaultCardID') === card.id) {
               return true;
            }
         }
         return false;
      }),
      title: 'Credit Card',
      attributeBindings: ['isInvalid', 'hasAgreed'],

      actions: {
         edit: function edit() {
            this.set('isEditing', true);
            this.set('disabled', false);
         },
         cancel: function cancel() {
            this.set('isEditing', false);
            this.set('disabled', true);
         },
         remove: function remove() {
            if (this.get('idNumber') > 0 && !this.get('isNewCard')) {
               this.confirmDelete();
            }
         },
         create: function create() {
            var self = this;
            if (this.get('isNewCard')) {
               self.createCard();
            }
         },
         save: function save() {
            var self = this;
            var result = DevExpress.validationEngine.validateGroup(this.get('recipientsGroup'));
            if (result && result.isValid) {
               self.set('isEditing', false);
               self.set('disabled', true);
               self.sendAction('save');
            }
         },
         slide: function slide(number) {
            if (_ember['default'].$("#item-card" + number).hasClass('in')) {
               // Item is Collapsed
               _ember['default'].$("#header-card" + number + " .menuClick").removeClass("glyphicon-menu-up");
               _ember['default'].$("#header-card" + number + " .menuClick").addClass("glyphicon-menu-down");
               _ember['default'].$("#header-card" + number + " .header-status").removeClass("hide");
            } else {
               // Item is Expanded
               _ember['default'].$("#header-card" + number + " .menuClick").removeClass("glyphicon-menu-down");
               _ember['default'].$("#header-card" + number + " .menuClick").addClass("glyphicon-menu-up");
               _ember['default'].$("#header-card" + number + " .header-status").addClass("hide");
            }
         },
         showTerms: function showTerms() {
            _ember['default'].$("#dxPopupTermsContainer").dxPopup("instance").show();
         },
         showPolicy: function showPolicy() {
            _ember['default'].$("#dxPopupPrivacyContainer").dxPopup("instance").show();
         }
      },
      createCard: function createCard() {
         var self = this;
         var idNumber = this.get('idNumber');
         var selector = '#item-card' + idNumber;
         var stripe = this.get('stripe');

         var container = document.querySelector(selector);
         var form = container.querySelector('form');
         var error = form.querySelector('.error');
         var errorMessage = error.querySelector('.message');
         /*let resetButton = container.querySelector('a.reset');*/

         if (stripe && form) {
            DevExpress.ui.notify("Creating Payment Card ...", "info", 2500);

            // Show a loading screen ...
            form.classList.add('submitting');

            // Disable all inputs ...
            self.disableInputs();

            DevExpress.ui.notify("Gathering Entered Details ...", "info", 2500);

            // Gather additional customer data collected in the form ...
            var _name = form.querySelector('#stp-element-name-' + idNumber);
            /*let address1 = form.querySelector('#stp-element-address-' + idNumber);
            let city = form.querySelector('#stp-element-city-' + idNumber);
            let state = form.querySelector('#stp-element-state-' + idNumber);*/
            var zip = form.querySelector('#stp-element-zip-' + idNumber);

            var msg = undefined;
            var rex1 = new RegExp("^[A-Za-z][A-Za-z\\-\\'\\`\\s]+$");
            var rex2 = new RegExp("^[a-zA-Z0-9\\-\\s]+$");

            if (!_name || !_name.value) {
               msg = 'Name is required';
            } else if (_name.value.length < 2) {
               msg = 'Name must be at least 2 characters!';
            } else if (!rex1.test(_name.value)) {
               msg = "Name may only contain letters, hyphens and spaces!";
            } else if (!zip || !zip.value) {
               msg = 'Postcode is required';
            } else if (zip.value.length <= 3 || zip.value.length >= 10) {
               msg = 'Postcode must be 3 to 10 characters!';
            } else if (!rex2.test(zip.value)) {
               msg = "May only contain letters, numbers, hyphens and spaces!";
            }
            if (msg) {
               form.classList.remove('submitting');
               error.classList.add('visible');
               errorMessage.innerText = msg;
               DevExpress.ui.notify("Error Creating Payment Card: " + msg, "error", 3000);
               self.enableInputs();
               return;
            }
            var additionalData = {
               name: _name ? _name.value : undefined,
               /*address_line1: address1 ? address1.value : undefined,
               address_city: city ? city.value : undefined,
               address_state: state ? state.value : undefined,*/
               address_zip: zip ? zip.value : undefined
            };

            // Use Stripe.js to create a token. We only need to pass in one Element
            // from the Element group in order to create a token. We can also pass
            // in the additional customer data we collected in our form.
            var elements = this.get('stripeElements');

            if (elements && elements.length > 0) {
               DevExpress.ui.notify("Creating Card Token ...", "info", 2500);
               stripe.createToken(elements[0], additionalData).then(function (result) {
                  // Stop loading ...
                  form.classList.remove('submitting');

                  if (result.error) {
                     // Inform the user if there was an error ...
                     error.classList.add('visible');
                     errorMessage.innerText = result.error.message;
                     DevExpress.ui.notify("Error Creating Payment Card: " + result.error.message, "error", 3000);
                  } else if (result.token) {
                     // If we received a token, show the token ID ...
                     form.querySelector('.token').innerText = result.token.id;
                     form.classList.add('submitted');
                     error.classList.remove('visible');

                     // Send the token to the server ...
                     DevExpress.ui.notify("Payment Token Created: " + result.token.id, "success", 2500);

                     // Submit the form ...
                     self.set('isEditing', false);
                     self.sendAction('saveToken', result.token);
                  } else {
                     // Otherwise, un-disable inputs.
                     error.classList.remove('visible');
                  }
                  self.enableInputs();
               });
            } else {
               form.classList.remove('submitting');
               DevExpress.ui.notify("Add New Card Failed: Form Elements Invalid!", "error", 2500);
            }
         } else {
            form.classList.remove('submitting');
            DevExpress.ui.notify("Add New Card Failed: Form Invalid!", "error", 2500);
         }
      },
      confirmDelete: function confirmDelete() {
         var self = this;
         var result = DevExpress.ui.dialog.confirm("Are you sure you want to delete this Card?", "Confirm Delete");

         result.done(function (dialogResult) {
            if (dialogResult) {
               DevExpress.ui.notify("Removing Card...", "info", 2500);
               self.sendAction('deleteCard', self.get('card'));
            }
         });
      },

      _initialize: (function () {
         var self = this;
         var isNew = this.get('isNewCard');
         //let validationGroup = this.get('group');

         if (isNew) {
            self.set('isEditing', true);
            self.renderElements(isNew);
         } else {
            self.set('isEditing', false);
            self.renderElements(isNew);
            self.disableInputs();
         }
      }).on('didInsertElement'),

      enableInputs: function enableInputs() {
         var selector = '#item-card' + this.get('idNumber');
         var container = document.querySelector(selector);

         if (container) {
            var form = container.querySelector('form');
            if (form) {
               Array.prototype.forEach.call(form.querySelectorAll("input[type='text'], input[type='email'], input[type='tel']"), function (input) {
                  input.removeAttribute('disabled');
               });
            }
         }
      },

      disableInputs: function disableInputs() {
         var selector = '#item-card' + this.get('idNumber');
         var container = document.querySelector(selector);

         if (container) {
            var form = container.querySelector('form');
            if (form) {
               Array.prototype.forEach.call(form.querySelectorAll("input[type='text'], input[type='email'], input[type='tel']"), function (input) {
                  input.setAttribute('disabled', 'true');
               });
            }
         }
      },

      registerElements: function registerElements(elements, selector) {
         var container = document.querySelector(selector);
         var form = container.querySelector('form');
         var error = form.querySelector('.error');
         var errorMessage = error.querySelector('.message');

         // Listen for errors from each Element, and show error messages in the UI.
         elements.forEach(function (element) {
            element.on('change', function (event) {
               if (event.error) {
                  error.classList.add('visible');
                  errorMessage.innerText = event.error.message;
               } else {
                  error.classList.remove('visible');
               }
            });
         });
         this.set('stripeElements', elements);
      },

      renderElements: function renderElements(isNewCard) {
         var idNumber = this.get('idNumber');
         var selector = '#item-card' + idNumber;
         var stripeObj = new _stripe['default'](_frontendConfigEnvironment['default'].stripe.publishableKey);
         var elements = stripeObj.elements({
            // Stripe examples are localized to specific languages
            // Elements automatically detects your user's locale ...
            //locale: window.__exampleLocale
            locale: 'auto'
         });
         this.set('stripe', stripeObj);

         // Floating labels ...
         var inputs = document.querySelectorAll(selector + ' .input');
         Array.prototype.forEach.call(inputs, function (input) {
            if (input.value && input.value.length > 0) {
               input.classList.remove('empty');
            }
            input.addEventListener('focus', function () {
               input.classList.add('focused');
            });
            input.addEventListener('blur', function () {
               input.classList.remove('focused');
            });
            input.addEventListener('keyup', function () {
               if (input.value.length === 0) {
                  input.classList.add('empty');
               } else {
                  input.classList.remove('empty');
               }
            });
         });

         // Custom styling passed to options when creating an Element...
         var elemStyle = {
            base: {
               color: '#202020',
               /*lineHeight: '24px',*/
               fontFamily: 'Arial, Helvetica, sans-serif',
               fontSmoothing: 'antialiased',
               fontSize: '16px',
               '::placeholder': {
                  color: 'transparent'
               },
               ':-webkit-autofill': {
                  color: '#e39f48'
               }
            },
            invalid: {
               color: '#d9534f',
               iconColor: '#d9534f',
               '::placeholder': {
                  color: 'transparent'
               }
            }
         };
         var elementClasses = {
            focus: 'focused',
            empty: 'empty',
            invalid: 'invalid'
         };

         // Add instances of card elements into appropriate <div> ...
         var cardNumberElement = _ember['default'].$('#stp-element-number-' + idNumber);
         var cardExpiryElement = _ember['default'].$('#stp-element-expiry-' + idNumber);
         var cardCvcElement = _ember['default'].$('#stp-element-cvc-' + idNumber);
         var formElement = _ember['default'].$('#stp-payment-form-' + idNumber);

         if (isNewCard) {
            var cardNumber = elements.create('cardNumber', { style: elemStyle, classes: elementClasses });
            if (cardNumberElement) {
               cardNumber.mount('#stp-element-number-' + idNumber);
            }
            var cardExpiry = elements.create('cardExpiry', { style: elemStyle, classes: elementClasses });
            if (cardExpiryElement) {
               cardExpiry.mount('#stp-element-expiry-' + idNumber);
            }
            var cardCvc = elements.create('cardCvc', { style: elemStyle, classes: elementClasses });
            if (cardCvcElement) {
               cardCvc.mount('#stp-element-cvc-' + idNumber);
            }
            if (formElement) {
               formElement.removeClass('hidden');
            }
            this.registerElements([cardNumber, cardExpiry, cardCvc], selector);
         }
      },

      _destroy: (function () {
         this.$("#dxFormCardDetailsContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         var disabled = this.get('disabled');
         if (disabled) {
            this.disableInputs();
         } else {
            this.enableInputs();
         }
      }).observes('disabled'),

      _canEditChanged: (function () {
         if (this.get('canEdit') === false) {
            this.send('cancel');
         }
      }).observes('canEdit')
   });
});