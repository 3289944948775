define('frontend/components/dx-bar-chart', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
   exports['default'] = _ember['default'].Component.extend({
      format: 'month',

      _initialize: (function () {
         this.$("#dxBarChartContainer").dxChart({
            disabled: this.get('disabled'),
            dataSource: this.get('dataSource'),
            moment: _moment['default'],

            size: {
               height: 300
            },
            commonPaneSettings: {
               border: {
                  visible: true,
                  top: false,
                  right: false
               }
            },
            commonSeriesSettings: {
               argumentField: 'timestamp'
            },
            legend: {
               visible: false
            },
            series: {
               type: 'bar',
               color: '#82c785',
               valueField: 'amount',
               name: 'Billing History'
            },
            title: {
               font: {
                  family: 'Arial',
                  size: 20
               },
               text: this.get('title')
            },
            tooltip: {
               enabled: true,
               location: 'edge',
               format: {
                  type: 'currency',
                  precision: 2
               },
               font: {
                  family: 'Arial',
                  size: 15
               },
               customizeTooltip: function customizeTooltip(pointInfo) {
                  return {
                     fontColor: '#6495ed',
                     text: "Amount: " + pointInfo.valueText
                  };
               }
            },
            argumentAxis: {
               //argumentType: 'datetime',
               discreteAxisDivisionMode: 'betweenLabels',
               type: 'discrete',
               position: 'bottom',
               tick: { visible: true },
               tickInterval: { months: 1 },
               minorTick: { visible: false },
               minorTickInterval: { days: 1 },
               grid: { visible: false },
               label: {
                  font: {
                     family: 'Arial',
                     size: 14
                  },
                  format: {
                     /*type: this.get('format'),*/
                     formatter: function formatter(originalValue) {
                        return (0, _moment['default'])(originalValue).format('DD/MM/YYYY');
                     }
                  }
               }
            },
            valueAxis: {
               discreteAxisDivisionMode: 'crossLabels',
               tick: { visible: true },
               tickInterval: 1,
               minorTick: { visible: false },
               grid: { visible: true },
               label: {
                  font: {
                     family: 'Arial',
                     size: 14
                  },
                  format: 'currency',
                  visible: true
               }
            }
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxBarChartContainer").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxBarChartContainer").dxChart('option', 'disabled', this.get('disabled'));
      }).observes('disabled')
   });
});