define("frontend/components/device-battery", ["exports", "ember"], function (exports, _ember) {
   exports["default"] = _ember["default"].Component.extend({
      batteryLevels: [{ key: 0, label: "Missing", value: 1000 }, { key: 1, label: "Protected", value: 2500 }, { key: 2, label: "Critical", value: 2800 }, { key: 3, label: "Flat", value: 3100 }, { key: 4, label: "Low", value: 3500 }, { key: 5, label: "Medium", value: 3800 }, { key: 6, label: "High", value: 4050 }, { key: 7, label: "Full", value: 4200 }],
      charging: false,
      current: 0,
      errorType: 0,
      soc: 0,
      millivolts: 0.0,
      volts: 0.0,
      state: 'Missing',

      _initialize: (function () {
         this.updateBattery();
      }).on('init'),

      getBatteryState: function getBatteryState() {
         var index = 0;
         var mVolts = this.get('millivolts');
         var levels = this.get('batteryLevels');

         if (mVolts <= levels[0].value) {
            index = 0; // Missing
         } else if (mVolts <= levels[1].value) {
               index = 1; // Protected
            } else if (mVolts <= levels[2].value) {
                  index = 2; // Critical
               } else if (mVolts <= levels[3].value) {
                     index = 3; // Flat
                  } else if (mVolts <= levels[4].value) {
                        index = 4; // Low
                     } else if (mVolts <= levels[5].value) {
                           index = 5; // Medium
                        } else if (mVolts <= levels[6].value) {
                              index = 6; // High
                           } else {
                                 index = 7; // Full
                              }
         return levels[index].label;
      },
      getErrorType: function getErrorType() {
         var mVolts = this.get('millivolts');
         var levels = this.get('batteryLevels');

         if (mVolts <= levels[0].value) {
            return 0; // RED
         } else if (mVolts <= levels[1].value) {
               return 0; // RED
            } else if (mVolts <= levels[2].value) {
                  return 0; // RED
               } else if (mVolts <= levels[3].value) {
                     return 1; // ORANGE
                  } else if (mVolts <= levels[4].value) {
                        return 1; // ORANGE
                     } else if (mVolts <= levels[5].value) {
                           return 2; // BLUE
                        } else if (mVolts <= levels[6].value) {
                              return 2; // BLUE
                           } else {
                                 return 3; // GREEN
                              }
      },
      updateBattery: function updateBattery() {
         var errorType = this.getErrorType();
         var state = this.getBatteryState();
         console.log('Device Battery: mV: %f, Error type: %d, State: %s', this.get('millivolts'), errorType, state);
         this.set('errorType', errorType);
         this.set('state', state);
      },
      _millivoltsChanged: (function () {
         this.updateBattery();
      }).observes('millivolts')
   });
});