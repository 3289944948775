define('frontend/components/dx-accordion-analytics', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
   exports['default'] = _ember['default'].Component.extend({
      titleTemplate: function titleTemplate(data, titleClass, iconClass) {
         return _ember['default'].$("<div>").addClass(titleClass).append("<table><tr>" + "<td style='width:40px'><span class='" + iconClass + " dx-icon-title'></span></td>" + "<td style='width:100%'><h3>" + data.caption + "</h3></td>" + "<td style='width:40px;'><h3 style='margin-right:30px'>" + data.logs.length + "</h3></td></tr></table></div>");
      },
      _initialize: (function () {
         var self = this;

         this.$("#dxAccordionContainerAnalytics").dxAccordion({
            dataSource: self.get('dataSource'),
            disabled: this.get('disabled'),
            animationDuration: 300,
            collapsible: true,
            multiple: true,
            noDataText: 'No data for this Device',
            selectedItems: [this.dataSource[0]],

            itemTitleTemplate: function itemTitleTemplate(itemData, itemIndex, itemElement) {
               if (itemData) {
                  if (itemIndex === 0) {
                     itemElement.append(self.titleTemplate(itemData, 'accordion-label-outage', 'dx-icon-clear'));
                  } else {
                     itemElement.append(self.titleTemplate(itemData, 'accordion-label-reset', 'dx-icon-warning'));
                  }
               } else {
                  itemElement.append("<div class='accordion-label'><h3>Device Overview</h3></div>");
               }
            },
            itemTemplate: function itemTemplate(itemData, itemIndex, itemElement) {
               if (itemIndex === 0) {
                  var numLogs = itemData.logs.get('length');
                  var maxLogs = (parseInt(numLogs / 10) + 1) * 10;

                  var outagesList = _ember['default'].$("<div>").dxList({
                     dataSource: itemData.logs,
                     allowItemDeleting: false,
                     allowItemReordering: false,
                     scrollingEnabled: true,
                     scrollByThumb: true,
                     selectionMode: 'single',
                     showScrollbar: 'onHover',
                     editEnabled: false,
                     useNativeScrolling: false,
                     itemTemplate: function itemTemplate(itemData, itemIndex, itemElement) {
                        itemElement.append("<div class='pull-left'><span style='margin-right:10px'>#" + (itemIndex + 1) + "</span>" + "<span class='dx-icon-arrowright dx-icon-xs'></span><span class='horiz-margin-md'>" + itemData.device + "</span>" + "<span class='label-danger'>" + itemData.status + "</span></div><span class='horiz-margin-md'>at</span>" + "<span>" + (0, _moment['default'])(itemData.created).format('DD/MM/YYYY hh:mm a') + "</span>" + "<div class='pull-right'><span class='horiz-margin-md'>for</span>" + "<span class='label-danger'>" + '3h 37min' + "</span></div>");
                     }
                  });
                  var outagesGauge = _ember['default'].$("<div>").dxBarGauge({
                     barSpacing: 2,
                     size: {
                        height: 250,
                        width: 250
                     },
                     scale: {
                        startValue: 0,
                        tickInterval: 1
                     },
                     endValue: maxLogs,
                     tooltip: {
                        enabled: true
                     },
                     title: {
                        text: itemData.caption,
                        font: {
                           family: 'Arial',
                           size: 20,
                           weight: 500
                        }
                     },
                     label: {
                        indent: 20,
                        format: {
                           type: "fixedPoint",
                           precision: 0
                        },
                        visible: true
                     },
                     palette: ["#d9534f", "#fff"],
                     values: [numLogs, maxLogs]
                  });
                  var listConatiner = _ember['default'].$("<div class='col-md-8 col-sm-8'>");
                  listConatiner.append(outagesList);
                  listConatiner.append("</div>");
                  var gaugeOuterContainer = _ember['default'].$("<div class='col-md-3 col-md-offset-0 col-sm-3 col-sm-offset-0'>");
                  var gaugeInnerContainer = _ember['default'].$("<div class='center'>");
                  gaugeInnerContainer.append(outagesGauge);
                  gaugeInnerContainer.append("<div class='value-overlay shift-right'><span>" + numLogs + "</span></div>");
                  gaugeInnerContainer.append("</div>");
                  gaugeOuterContainer.append(gaugeInnerContainer);
                  gaugeOuterContainer.append("</div>");

                  itemElement.append(listConatiner);
                  itemElement.append(gaugeOuterContainer);
               } else {
                  var numLogs = itemData.logs.get('length');
                  var maxLogs = (parseInt(numLogs / 10) + 1) * 10;

                  var resetsList = _ember['default'].$("<div>").dxList({
                     dataSource: itemData.logs,
                     allowItemDeleting: false,
                     allowItemReordering: false,
                     scrollingEnabled: true,
                     scrollByThumb: true,
                     selectionMode: 'single',
                     showScrollbar: 'onHover',
                     editEnabled: false,
                     useNativeScrolling: false,
                     itemTemplate: function itemTemplate(itemData, itemIndex, itemElement) {
                        itemElement.append("<div class='pull-left'><span style='margin-right:10px'>#" + (itemIndex + 1) + "</span>" + "<span class='dx-icon-arrowright dx-icon-xs'></span><span class='horiz-margin-md'>" + itemData.device + "</span>" + "<span class='label-success'>" + itemData.status + "</span></div><span class='horiz-margin-md'>at</span><div class='pull-right'><span>" + (0, _moment['default'])(itemData.created).format("DD/MM/YYYY hh:mm a") + "</span></div>");
                     }
                  });
                  var resetsGauge = _ember['default'].$("<div>").dxBarGauge({
                     barSpacing: 2,
                     size: {
                        height: 250,
                        width: 250
                     },
                     scale: {
                        startValue: 0,
                        tickInterval: 1
                     },
                     endValue: maxLogs,
                     tooltip: {
                        enabled: true
                     },
                     title: {
                        text: itemData.caption,
                        font: {
                           family: 'Arial',
                           size: 20,
                           weight: 500
                        }
                     },
                     label: {
                        indent: 20,
                        format: {
                           type: "fixedPoint",
                           precision: 0
                        },
                        visible: true
                     },
                     palette: ["sandybrown", "#fff"],
                     values: [numLogs, maxLogs]
                  });
                  var listConatiner = _ember['default'].$("<div class='col-md-8 col-sm-8'>");
                  listConatiner.append(resetsList);
                  listConatiner.append("</div>");
                  var gaugeOuterContainer = _ember['default'].$("<div class='col-md-3 col-md-offset-0 col-sm-3 col-sm-offset-0'>");
                  var gaugeInnerContainer = _ember['default'].$("<div class='center'>");
                  gaugeInnerContainer.append(resetsGauge);
                  gaugeInnerContainer.append("<div class='value-overlay shift-right'><span>" + numLogs + "</span></div>");
                  gaugeInnerContainer.append("</div>");
                  gaugeOuterContainer.append(gaugeInnerContainer);
                  gaugeOuterContainer.append("</div>");

                  itemElement.append(listConatiner);
                  itemElement.append(gaugeOuterContainer);
               }
            }
         });
      }).on('didInsertElement'),

      _destroy: (function () {
         this.$("#dxAccordionContainerAnalytics").remove();
      }).on('willDestroyElement'),

      _disabledChanged: (function () {
         this.$("#dxAccordionContainerAnalytics").dxAccordion('option', 'disabled', this.get('disabled'));
      }).observes('disabled')
   });
});