define('frontend/components/device-list-item', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      classNames: [''],
      tagName: 'tr',
      isEditName: false,

      actions: {
         cancel: function cancel() {
            this.set('isEditName', false);
         },
         edit: function edit() {
            this.set('isEditName', true);
         },
         save: function save() {
            this.get('item').save();
            this.set('isEditName', false);
         },
         update: function update() {
            this.get('item').reload();
         }
      }
   });
});