define('frontend/components/device-log-item', ['exports', 'ember', 'moment', 'ember-require-module'], function (exports, _ember, _moment, _emberRequireModule) {
   (0, _emberRequireModule['default'])('moment-duration-format');

   exports['default'] = _ember['default'].Component.extend({
      screen: _ember['default'].inject.service(),
      classNames: ['device-log-item'],
      deviceName: '',
      isOutage: true,
      tagName: 'tr',
      itemIndex: 0,
      itemNumber: _ember['default'].computed(function () {
         return this.itemIndex + 1;
      }),
      itemData: null,
      status: _ember['default'].computed(function () {
         var online = this.get('itemData').status;
         if (online) {
            return 'Online';
         } else {
            return 'Offline';
         }
      }),
      createdOn: _ember['default'].computed(function () {
         var timeStamp = this.get('itemData').created;
         return (0, _moment['default'])(timeStamp).format('DD/MM/YYYY hh:mm:ss a');
      }),
      duration: _ember['default'].computed(function () {
         var range = (0, _moment['default'])(this.get('itemData').duration);
         if (range === undefined || range === null) {
            return 'error!';
         }
         var result = '';
         if (range >= 0) {
            var duration = _moment['default'].duration(range / 1000, 'seconds');
            if (duration) {
               var days = duration.get('days');
               var hours = duration.get('hours');
               var minutes = duration.get('minutes');
               var seconds = duration.get('seconds');
               if (days > 0) {
                  result = result + days + "d ";
               }
               if (hours > 0) {
                  result = result + hours + "h ";
               }
               if (minutes > 0) {
                  result = result + minutes + "m ";
               }
               if (seconds > 0) {
                  result = result + seconds + "s";
               }
            }
         } else {
            var current = this.get('itemData').created;
            var next = new Date();
            var range1 = (0, _moment['default'])(next) - (0, _moment['default'])(current); // milliseconds
            var duration1 = _moment['default'].duration(range1, 'ms');
            if (duration1) {
               var days = duration1.get('days');
               var hours = duration1.get('hours');
               var minutes = duration1.get('minutes');
               var seconds = duration1.get('seconds');
               if (days > 0) {
                  result = result + days + "d ";
               }
               if (hours > 0) {
                  result = result + hours + "h ";
               }
               if (minutes > 0) {
                  result = result + minutes + "m ";
               }
               if (seconds > 0) {
                  result = result + seconds + "s";
               }
            }
         }
         return result;
      })
   });
});